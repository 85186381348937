@-webkit-keyframes footer-link-color {
    from {
        color: #3d3d3d;
    }

    to {
        color: #ed0b0b;
    }
}

@-ms-keyframes footer-link-color {
    from {
        color: #3d3d3d;
    }

    to {
        color: #ed0b0b;
    }
}

@-o-keyframes footer-link-color {
    from {
        color: #3d3d3d;
    }

    to {
        color: #ed0b0b;
    }
}

@-moz-keyframes footer-link-color {
    from {
        color: #3d3d3d;
    }

    to {
        color: #ed0b0b;
    }
}

@keyframes footer-link-color {
    from {
        color: #3d3d3d;
    }

    to {
        color: #ed0b0b;
    }
}

@-webkit-keyframes content-link-color {
    from {
        color: #1b1b1b;
    }

    to {
        color: #ed0b0b;
    }
}

@-ms-keyframes content-link-color {
    from {
        color: #1b1b1b;
    }

    to {
        color: #ed0b0b;
    }
}

@-o-keyframes content-link-color {
    from {
        color: #1b1b1b;
    }

    to {
        color: #ed0b0b;
    }
}

@-moz-keyframes content-link-color {
    from {
        color: #1b1b1b;
    }

    to {
        color: #ed0b0b;
    }
}

@keyframes content-link-color {
    from {
        color: #1b1b1b;
    }

    to {
        color: #ed0b0b;
    }
}

html,
body {
    height: 100%;
}

#root,
#root-div {
    height: 100vh;
}

body {
    font-family: 'Poppins';
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
    text-align: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#main {
    font-family: 'Poppins';
    flex: 1 0 auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

section {
    font-family: 'Poppins';
    background-color: #fff;
    color: #3d3d3d;
    font-size: calc(10px + 2vmin);
    text-align: center;
}

footer {
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #3d3d3d;
    background-color: #ffffff;
    font-weight: 400;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;
    flex-shrink: 0;
}

a {
    text-decoration: none;
}

.login-page-header .content-page-logo {
    margin-top: 45px;
    width: 159px;
    height: 28px;
}

.content-page-logo {
    width: 184px;
    height: auto;
    margin-top: 13px;
}

.content-page-logo:hover {
    transform: scale(1.10);
}

.content-page-header.hidden .menu-drawer{
    top: 0px;
    margin-top: 0px;
}

.content-page-header {
    background:#dddddd;
    border-radius: 0;
    height: 130px;
    position: fixed;
    width: 100%;
    z-index: 5;
    margin-top: -1px;
    visibility: visible;
    transition: all 0.6s ease-in-out;
}

.content-page-header.hidden {
    top: -69px;
    margin-top: -25px;
}

.content-page-header.hidden .menu-drawer{
    top: 0px;
    margin-top: 0px;
}

.footer-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.footer-icon {
    width: 5vw;
    height: 5vw;
    min-width: 36px;
    min-height: 36px;
    max-width: 48px;
    max-height: 48px;
    border-radius: 25%;
}

.footer-icon:hover {
    filter: brightness(75%);
    transform: scale(1.1);
}

.legal-links-div {
    text-align: center;
    width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
    margin: 10px auto 20px;
}

a.footer-link {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3d3d3d;
    margin-left: 8px;
    display: inline-block;
}

a.footer-link {
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #3d3d3d;
}

a.mail-link, 
a.business-link,
a.legal-link {
    color: #3d3d3d;
    font-weight: 600;
}

a.footer-link:hover {
    color: 	#ed0b0b;
    -webkit-animation: footer-link-color 0.5s ease;
    -moz-animation: footer-link-color 0.5s ease;
    -o-animation: footer-link-color 0.5s ease;
    animation: footer-link-color 0.5s ease;
}

a.mail-link:hover,
a.business-link:hover,
a.legal-link:hover {
    color: 	#ed0b0b;
    -webkit-animation: content-link-color 0.5s ease;
    -moz-animation: content-link-color 0.5s ease;
    -o-animation: content-link-color 0.5s ease;
    animation: content-link-color 0.5s ease;
}

.content-subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #3d3d3d;
    text-align: left;
    margin-top: 48px;
    margin-bottom: 24px;
}

.content-subtitle.small-margin {
    margin-top: 24px;
}

.content-subtitle.top-title {
    margin-top: 24px;
}

p.long-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: #3d3d3d;
    text-align: justify;
}

ul.service-list,
ul.cookies-list {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: #3d3d3d;
    text-align: left;
    padding-inline-start: 16px;
    padding-left: 36px;
    margin-bottom: 32px;
}

ul.service-list > li,
ul.cookies-list > li {
    margin-bottom: 16px;
}

ul.service-details {
    margin-bottom: 16px;
}

.menu-option-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #3d3d3d;
    display: inline-block;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(55, 53, 160, 0.5);
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    text-align: left;
}

.menu-option-button.no-border {
    border: none;
}

.menu-option-button-subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #15182A;
    display: inline-block;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(55, 53, 160, 0.5);
    padding-left: 9px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    text-align: left;
}

.menu-drawer {
    overflow: auto;
    max-height: 90vh;
    width: 83.5%;
    min-width: 315px;
    position: fixed;
    z-index: 7;
    text-align: center;
    -webkit-transform: translateX(-100%) translateZ(0);
    transform: translateX(-100%);
    -webkit-transition: transform 0.5s ease-out;
    -moz-transition: transform 0.5s ease-out;
    -o-transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
    background: #dddddd;
    border-radius: 0px 0px 40px 40px;
}

.menu-drawer.open {
    box-shadow: 45px 10px 80px #000000;
    -webkit-transform: translateX(0) translateZ(0);
    transform: translateX(0);
}

.menu-bar {
    position: absolute;
    top: 7px;
    left: 9px;
    padding: 16px;
    width: 30px;
    height: auto;
    z-index: 4;
}

.close-menu {
    width: 18px;
    height: 18px;
}

.menu-bar:hover,
.close-menu:hover {
    filter: brightness(75%);
    transform: scale(1.1); 
    cursor: pointer;
}

.menu-container {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
    background-color: transparent;
}

.menu-container.menu-top {
    margin-top: 24px;
}

.menu-container.menu-options-container {
    clear: both;
    overflow-y: scroll;
    height: fit-content;
    padding-bottom: 1px;
    margin-bottom: 20px;
}

.home-image {
    width: 49px;
    height: auto;
}

.home-image:hover {
    filter: brightness(75%);
    transform: scale(1.1);
}

#content {
    flex: 1 0 auto;
}

#menu-div {
    position: fixed;
    z-index: 7;
}

#exit-menu-div {
    width: 50%;
    text-align: right;
    float: left;
    padding-top: 10px;
}

#logo-menu-div {
    width: 50%;
    text-align: left;
    float: left;
}

.page-container {
    text-align: center;
    min-height: calc(100vh - 500px);
    padding-left: 25px;
    padding-right: 25px;
}

.title-container {
    background-color: #ffffff;
    padding-top: 5px;
    max-width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 69px;
}

h1.page-title {
    color: #3d3d3d;
    font-family: Poppins;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 48.4px;
    text-align: center;
    padding-bottom: 4px;
    margin-bottom: 10px;
    margin-top: 75px;
}

.bold-text {
    font-weight: bold;
}

.cookie-consent-dialog-container {
    position: fixed;
    bottom: 0;
    width: 100%
}

.cookie-consent-dialog {
    width: 80%;
    padding: 10px 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    text-align: center;
    box-shadow: 0 -2px 3px rgba(0,0,0,0.2);
    z-index: 10; 
    border-radius: 15px;
}

.accept-cookies {
    color:#ffffff;
    background-color: #d72323;
    border-radius: 12px;
    border: 1px solid #15182A;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 16px;
    padding: 12px;
    width: 75px;
    cursor: pointer;
    font-size: 14px;
}

.decline-cookies {
    color:#3d3d3d;
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid #15182A;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 16px;
    padding: 12px;
    width: 75px;
    cursor: pointer;
    font-size: 14px;
}

.make-order-button {
    color:#ffffff;
    background-color: #d72323;
    border-radius: 12px;
    padding: 12px;
    width: 150px;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    margin-top: 6px;
}

.accept-cookies:hover,
.decline-cookies:hover,
.make-order-button:hover {
    filter: brightness(75%);
    transform: scale(1.1);
}


@media (orientation:landscape) {
    .menu-drawer {
        width: 55%;
        height: calc(100vh - 50px);
    }
}

@media (min-width: 600px) {
    .content-page-header {
        height: 65px;
    }

    .make-order-button {
        display: inline;
        position: absolute;
        top: 10px;
        margin-left: 32px;
        margin-top: 0;
    }

    h1.page-title {
        margin-top: 10px;
    }
}

@media (min-width: 1024px) {

    .content-page-header.desktop.hidden .content-page-logo {
        margin-top: 10px;
        width: 250px;
    }

    .login-page-header .content-page-logo {
        margin-top: 38.5px;
        width: 230px;
        height: 55px;
    }

    .content-page-header.desktop.hidden .menu-drawer {
        margin-top: -30px;
    }

    .content-page-header.desktop.hidden {
        position: absolute;
        top: 0;
        margin-top: 30px;
        background: transparent;
    }

    .content-page-header.desktop.hidden .menu-drawer {
        margin-top: -30px;
    }

    .content-page-header.hidden .header-login-container {
        display: none;
    }

    .content-page-header.hidden .menu-bar {
        margin-left: 150px;
    }

    .content-subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #3d3d3d;
        width: 730px;
        margin-left: auto;
        margin-right: auto;
    }

    .content-subtitle.bold-text {
        font-weight: bold;
    }    

    p.long-content {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
        width: 730px;
        margin-left: auto;
        margin-right: auto;
    }

    ul.service-list,
    ul.cookies-list {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
        width: 730px;
        margin-left: auto;
        margin-right: auto; 
        padding-inline-start: 40px;
        padding-left: 80px;
    }

    .menu-drawer {
        width: 300px;
        height: fit-content;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #7674F5;
        background: rgba(118, 116, 245, 0.5);
    }

}